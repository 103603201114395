
import Component, { ComponentMethods } from "@/module/component/component"

import elemIcon from "@/components/elem-icon.vue"
import elemPrompt from "@/components/elem-prompt.vue"
import elemOptions from "@/components/elem-options.vue"
import compMenu from "@/components/comp-menu.vue"
import CompModal from "@/components/comp-modal.vue"

import Message from "@/module/interactive/message"
import Request, { RequestPage } from "@/module/request/request"
import Utils from "@/module/utils/utils"
import Cache from "@/module/cache/cache"
import Headway from "@/module/utils/headway"
import Loading from "@/module/loading/loading"

class ToolView extends ComponentMethods implements ComponentEntity {
    public title: string = "用户管理"

    private users: obj[] = null

    private requestPage: RequestPage<obj>

    private filter_title = {}

    private search: string = null

    private mode: string = Cache.get("UserPageMode", "basis")

    private menu_config: obj[] = null

    private model_title: string = "数据"
    // 模块链接
    private model_url: string = null

    private filters = [
        {
            name: "status",
            title: "状态值",
            data: [
                {
                    title: "未知",
                    value: "0",
                },
                {
                    title: "正常",
                    value: "1",
                },
                {
                    title: "禁用",
                    value: "3",
                },
                {
                    title: "已删除",
                    value: "4",
                },
            ],
        },
    ]

    components = {
        elemIcon,
        elemPrompt,
        elemOptions,
        compMenu,
        CompModal,
    }

    async created() {
        // 获取菜单配置
        await this.getMenuConfig()
        // 分页实体
        this.requestPage = new RequestPage("ADMIN://User/FindAllToPage", {
            method: "POST",
            size: 20,
            onChange: res => {
                this.users = res
            },
        })

        window.addEventListener("message", evt => {
            if (evt.data?.type === "CloseModel") {
                this.$refs.comp_model.onClose()
            }
        })

        // setTimeout(() => {
        //     this.onOpenModel(
        //         {
        //             name: "开通科目",
        //             url: "http://127.0.0.1/admin/test.html",
        //         },
        //         { uuid: "123" }
        //     )
        // }, 3000)
    }

    activated() {
        this.requestPage?.reset()
    }

    onReachBottom() {
        this.requestPage?.load()
    }

    onSubmitSearch() {
        this.requestPage.setData({
            search: this.search,
        })
    }

    /**
     * 获取菜单配置
     */
    async getMenuConfig(): Promise<void> {
        return new Promise(resolve => {
            Request.get<obj[]>("ADMIN://User/GetMenu", null, {
                onFail: () => false,
            })
                .then(res => {
                    this.menu_config = res
                })
                .finally(resolve)
        })
    }

    /**
     * 价格单位 分 转 元，保留两位小数
     * @param amount 金额，单位：分
     */
    centsToYuan(amount: number): string {
        if (!amount) {
            return "0.00"
        }

        return (amount / 100).toFixed(2)
    }

    /**
     * 获取地址
     */
    getAddress(e: obj): string {
        var res = []

        Utils.each(["country", "province", "city"], v => {
            e[v] && res.push(e[v])
        })

        return res.length > 0 ? res.join("-") : "-"
    }

    /**
     * 随机生成十六进制颜色
     */
    getRandomHexColor() {
        return "#" + ("00000" + ((Math.random() * 0x1000000) << 0).toString(16)).substr(-6)
    }

    getMenu(disable: boolean): obj[] {
        let arr: obj[] = [
            {
                title: "操作",
                prompt: "删除用户成功后将无法恢复，请谨慎操作！",
                sub: [
                    {
                        id: disable ? "CancelDisable" : "Disable",
                        icon: "disable",
                        name: disable ? "取消禁用" : "禁用",
                    },
                    {
                        id: "Delete",
                        icon: "delete",
                        name: "删除用户",
                    },
                ],
            },
        ]

        if (this.menu_config && this.menu_config.length > 0) {
            arr.push({
                title: "管理",
                sub: this.menu_config,
            })
        }

        return arr
    }

    onSelectMenu(user: obj, evt: ElemEvent<string>) {
        switch (evt.value) {
            case "Disable":
                this.onDisableUser(user)
                break
            case "CancelDisable":
                this.onChangeDisableUser(user, false)
                break
            case "Delete":
                this.onDeleteUser(user)
                break
            case "Href":
                evt.data.href && this.jump(this.getUrl(user, evt.data.href))
                break
            case "Model":
                this.onOpenModel(evt.data, user)
        }
    }

    /**
     * 打开窗口
     */
    onOpenModel(data: obj, user: obj) {
        Loading.show()

        this.model_title = data.name
        this.model_url = data.url
        this.current_user = user

        this.$nextTick(() => {
            new Headway(this, "isLoadedIframe").on().then(() => {
                this.$refs.comp_model.onDisplay()

                this.$refs.iframe.contentWindow.postMessage(
                    {
                        type: "OpenMembership",
                        user_id: this.current_user.uuid,
                        authorization_token: Cache.get("token", ""),
                        authorization_user: Cache.get("user_id", ""),
                    },
                    "*"
                )

                Loading.hide()
            })
        })
    }

    /**
     * 解析 url
     */
    getUrl(data: obj, url: string): string {
        return new Function(`return \`${url.replace(/&{(\w*)}/g, "${this.data.$1}")}\``).call({
            data: data,
        })
    }

    onDisableUser(user: obj) {
        Message.info("确认禁用当前用户？", true)
            .onConfirm(() => {
                this.onChangeDisableUser(user, true)
            })
            .build()
    }

    onChangeDisableUser(user: obj, disable: boolean) {
        Request.post("ADMIN://User/ChangeDisable", { user: user.uuid, disable }).then(() => {
            Message.success(disable ? "禁用成功" : "取消禁用成功")
            user.status = disable ? 3 : 1
        })
    }

    onDeleteUser(user: obj) {
        Message.info("确认删除当前用户？", true)
            .onConfirm(() => {
                Request.delete("ADMIN://User/Delete", { user: user.uuid }).then(() => {
                    Utils.each(
                        this.users,
                        () => "delete",
                        c => c.uuid === user.uuid
                    )
                    Message.success("删除成功")
                })
            })
            .build()
    }

    onChangeMode(mode: string) {
        this.mode = mode

        if (mode === "basis" || mode === "concise") {
            Cache.set("UserPageMode", mode, {
                storage: "local",
            })
        }
    }

    onLoadIframe() {
        this.isLoadedIframe = true
    }
}

export default Component.build(new ToolView())
